import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface ComponentPreviewCardV2Props {
  title: string;
  description: string;
  image?: any;
  video?: any;
  date?: string;
  categories?: string[];
  resourceLink: string;
  githubStars?: number;
}

export default function ComponentPreviewCardV2(
  props: ComponentPreviewCardV2Props
) {
  const dynamicImage: IGatsbyImageData | undefined = getImage(
    props.image?.childImageSharp?.gatsbyImageData
  );

  return (
    <OutboundLink href={props.resourceLink} target="__blank">
      <div className="rounded-xl overflow-hidden border transition-transform ease-in-out duration-300 hover:scale-[1.02]">
        {(dynamicImage === undefined || dynamicImage === null) &&
        props.video ? (
          <div className="flex justify-center items-center">
            <video
              src={props.video.publicURL}
              className=" object-contain"
              controls
              preload="metadata"
            />
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <GatsbyImage
              image={dynamicImage as IGatsbyImageData}
              objectFit="contain"
              className="object-contain"
              alt={props.title}
            />
          </div>
        )}
        <div className="px-4 py-4 font-inter font-normal">
          <div className="text-base mb-2 text-slate-600">{props.date}</div>
          <div className="text-2xl mb-2 text-black">{props.title}</div>
          <div className="text-gray-700 text-base">{props.description}</div>
        </div>

        {props.categories ? (
          <div className="px-4 pb-2">
            {props.categories?.map((category) => (
              <span className="inline-block bg-slate-800 rounded-full px-3 py-1 text-sm font-medium text-white mr-2 mb-2 font-inter">
                {category}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    </OutboundLink>
  );
}

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function CompanyRow() {
  return (
    <>
      <div className="mt-8 mb-4 text-center text-lg text-gray-700 font-inter">
        {" "}
        Our subscribers are top Android Engineers at companies like
      </div>
      <div className="flex flex-wrap justify-center items-center mb-4">
        <div className="m-2">
          <StaticImage
            src="../images/google-logo.png"
            alt="Google"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/airbnb-logo.png"
            alt="Airbnb"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/meta-logo.png"
            alt="Meta"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/square-logo.png"
            alt="Square"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/microsoft-logo.png"
            alt="Microsoft"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/twitter-logo.png"
            alt="Twitter"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/reddit-logo.png"
            alt="Reddit"
            placeholder="blurred"
            width={90}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/instacart-logo.png"
            alt="Instacart"
            placeholder="blurred"
            width={100}
          />
        </div>

        <div className="m-2">
          <StaticImage
            src="../images/jetbrains-logo.png"
            alt="JetBrains"
            placeholder="blurred"
            width={50}
          />
        </div>

        <div className="m-2">
          <StaticImage
            src="../images/netflix-logo.png"
            alt="Netflix"
            placeholder="blurred"
            width={90}
          />
        </div>

        <div className="m-2">
          <StaticImage
            src="../images/tiktok-logo.png"
            alt="TikTok"
            placeholder="blurred"
            width={100}
          />
        </div>

        <div className="m-2">
          <StaticImage
            src="../images/disney-hotstar-logo.png"
            alt="Disney Hotstar"
            placeholder="blurred"
            width={110}
          />
        </div>

        <div className="m-2">
          <StaticImage
            src="../images/dropbox-logo.png"
            alt="Dropbox"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/clubhouse-logo.webp"
            alt="Clubhouse"
            placeholder="blurred"
            width={100}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/turo-logo.png"
            alt="Turo"
            placeholder="blurred"
            width={80}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/soundcloud-logo.png"
            alt="Soundcloud"
            placeholder="blurred"
            width={80}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/american-express-logo.png"
            alt="American Express"
            placeholder="blurred"
            width={80}
          />
        </div>
        <div className="m-2">
          <StaticImage
            src="../images/phone-pe-logo.png"
            alt="Phone Pe"
            placeholder="blurred"
            width={90}
          />
        </div>
      </div>
    </>
  );
}

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";

interface SectionHeaderProps {
  title: string;
  viewMoreTitle?: string;
  viewMoreSlug?: string;
  viewMoreOutboundUrl?: string;
}

export default function SectionHeader(props: SectionHeaderProps) {
  const classes = useStyles();
  return (
    <Box width={1}>
      <div className={classes.sectionHeadingContainer}>
        <div className={classes.sectionHeading}>{props.title}</div>
        {props.viewMoreSlug ? (
          <Link to={props.viewMoreSlug} className={classes.sectionHeadingLink}>
            <div>{props.viewMoreTitle + "→"}</div>
          </Link>
        ) : props.viewMoreOutboundUrl ? (
          <OutboundLink
            href={props.viewMoreOutboundUrl}
            className={classes.sectionHeadingLink}
            target="__blank"
          >
            <div>{props.viewMoreTitle + "→"}</div>
          </OutboundLink>
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
}

const useStyles = makeStyles({
  sectionHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  sectionHeading: {
    color: "#222222",
    fontSize: 32,
    fontWeight: 600,
    fontFamily: "Nunito Sans",
    marginBottom: 32,
    marginRight: 16,
  },
  sectionHeadingLink: {
    color: "#222222",
    fontSize: 24,
    fontWeight: 600,
    fontFamily: "Nunito Sans",
    marginBottom: 32,
    "&:hover": {
      color: "#4636f7",
    },
  },
});
